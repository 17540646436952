@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Comforter&family=Comforter+Brush&family=Marck+Script&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Comforter&family=Comforter+Brush&family=Marck+Script&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.bad-script-regular {
  font-family: 'Bad Script', cursive;
  font-weight: 400;
  font-style: normal;
}

.comforter-regular {
  font-family: 'Comforter', cursive;
  font-weight: 300;
  font-style: normal;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-slate-200 dark:bg-slate-950;
}

h1 {
  @apply text-slate-900 dark:text-white comforter-regular;
}

h2,
h3,
h4,
h5,
h6 {
  @apply text-slate-900 dark:text-white bad-script-regular;
}

p {
  @apply text-slate-600 dark:text-slate-400 text-sm leading-loose lg:text-base lg:leading-relaxed;
}

@media screen and (max-width: 750px) {
  iframe {
    max-width: 100% !important;
    width: auto !important;
    height: auto !important;
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
